/* eslint-disable */
import { relativeTimeThreshold } from "moment";
import Setting from "../../setting.js";

export default {
  name: "AccountView",
  components: {},
  data: () => ({
    user: null,
    hasAccount: false,
    hasNotAccount: false,
    showAlert: false,
    alertType: null,
    alertMessage: "",
    valid: false,
    validAccount: false,
    loading: false,
    agree: false,
    name: "",
    firstName: "",
    lastName: "",
    email: "", //this.$store.getters.getCreateEmail,
    company: "",
    phone: "",
    code: "",
    country: null,
    status: null,
    styles: {
      container: "width: 100%; position: relative;",
      _alert: "position: absolute !important; width: 100%; top: -45px;",
    },
    statuses: [
      {
        text: "On-site",
      },
      {
        text: "Remote",
      },
      {
        text: "I will not join",
      },
    ],
    firstNameRules: [
      (v) => !!v || "First name is required",
      (v) => v.length <= 30 || "Name must be less than 30 characters",
    ],
    lastNameRules: [
      (v) => !!v || "Last name is required",
      (v) => v.length <= 30 || "Name must be less than 30 characters",
    ],
    companyRules: [
      (v) => !!v || "Company is required",
      (v) => v.length <= 30 || "Company must be less than 30 characters",
    ],
    phoneRules: [
      (v) => v.length <= 20 || "Phone must be less than 20 characters",
    ],
    emailRules: [
      (v) => !!v || "E-mail is required",
      (v) => /.+@.+/.test(v) || "E-mail must be valid",
    ],
  }),
  async mounted() {
    // console.warn(this.$route);

    /*
    if (this.$route.query.page == "list") {
      this.$router.push("/list");
    } else {
      await this.tryLogin();
    }
    */

    this.user = this.$store.getters.getUserAuth;

    if (this.user != null) {
      this.hasAccount = true;
      this.firstName = this.user.firstName;
      this.lastName = this.user.lastName;
      this.email = this.user.email;
      this.company = this.user.company;
      this.phone = this.user.phone;
      this.code = this.user.code;
      this.userCode = this.user.code;
      this.country = this.user.country;
      this.status = this.user.status;

      // console.warn("FirstLoad: " + this.$store.getters.getFirstLoad);

      let firstLoad = this.$store.getters.getFirstLoad;

      if (firstLoad) {
        this.$store.commit("firstLoadSave", false);
        // HACK remove comment when Montreal ready
        this.$router.push("/agenda");
      }
    } else {
      /*
      console.warn({
        route: this.$route,
      });
      */

      if (this.$route.query.action && this.$route.query.action == "create") {
        this.hasNotAccount = true;
        this.email = this.$store.getters.getCreateEmail;
      } else {
        this.$router.push("/");
      }
    }
  },
  computed: {
    getLanguage() {
      return this.country;
    },
  },
  methods: {
    async onSubmit() {
      this.$refs.form.validate();

      if (this.valid) {
        this.loading = true;
        let result;

        if (this.hasAccount) {
          result = await this.saveAccount();
          this.alertMessage = "Thank you for your update";
        } else {
          result = await this.addAccount();
          this.alertMessage = "Thank you for your registration";
        }

        setTimeout(() => {
          this.loading = false;
          /*
          console.warn({
            submitResult: result,
          });
         */

          if (result.data.success) {
            this.alertShow("success");
            this.hasAccount = true;
            localStorage["data"] = result.data.data.email;
          } else {
            localStorage["data"] = null;
            this.alertShow("error");

            if (result.data.errors.includes("UserExist")) {
              this.alertMessage = "User already exist.";
            } else {
              this.alertMessage = "Server error, contant administrator please.";
            }
          }
        }, 1000);
      }
    },
    async onSubmitAccount(event) {
      if (event) event.preventDefault();

      this.$refs.formAccount.validate();

      if (this.validAccount) {
        this.loading = true;
        const result = await this.getAccount();

        setTimeout(() => {
          this.loading = false;

          /*
          console.warn({
            submitAccountResult: result,
          });
          */

          if (result.data.success) {
            this.hasAccount = true;

            this.name = result.data.data.name;
            this.email = result.data.data.email;
            this.phone = result.data.data.phone;
            this.code = result.data.data.code;
            this.country = result.data.data.country;
            this.company = result.data.data.company;
            this.status = result.data.data.status;

            localStorage["data"] = result.data.data.email;
          } else {
            this.hasNotAccount = true;
          }
        }, 1000);
      }
    },
    async addAccount() {
      this.hideAlert();
      return await this.axios.post(
        Setting[process.env.NODE_ENV].API + "Form/Add",
        {
          FirstName: this.firstName,
          LastName: this.lastName,
          Email: this.email,
          Phone: this.phone,
          Code: this.code,
          Country: this.country,
          Company: this.company,
          Status: this.status,
        },
        {
          // withCredentials: true,
          headers: {
            // 'Access-Control-Allow-Credentials':true,
            "Access-Control-Allow-Origin": Setting[process.env.NODE_ENV].Source,
          },
        }
      );
    },
    async saveAccount() {
      this.hideAlert();
      return await this.axios.post(
        Setting[process.env.NODE_ENV].API + "Form/Update",
        {
          FirstName: this.firstName,
          LastName: this.lastName,
          Email: this.email,
          Phone: this.phone,
          Code: this.code,
          Country: this.country,
          Company: this.company,
          Status: this.status,
        },
        {
          // withCredentials: true,
          headers: {
            // 'Access-Control-Allow-Credentials':true,
            "Access-Control-Allow-Origin": Setting[process.env.NODE_ENV].Source,
          },
        }
      );
    },
    async getAccount() {
      return await this.axios.post(
        Setting[process.env.NODE_ENV].API + "Form/Account",
        {
          email: this.email,
        },
        {
          // withCredentials: true,
          headers: {
            // 'Access-Control-Allow-Credentials':true,
            "Access-Control-Allow-Origin": Setting[process.env.NODE_ENV].Source,
          },
        }
      );
    },
    async tryLogin() {
      const store = localStorage["data"];

      if (store) {
        this.email = store;
        await this.onSubmitAccount();
      }
    },
    onLogout() {
      delete localStorage["data"];

      this.hasAccount = false;
      this.hasNotAccount = false;

      this.$store.commit("userAuthSave", null);
      this.$router.push("/");
    },
    getStatuses() {
      return this.hasAccount
        ? this.statuses
        : this.statuses.filter((x) => x.text != "Canceled");
    },
    alertShow(alertType) {
      this.showAlert = true;
      this.alertType = alertType;

      if (alertType != "error") {
        setTimeout(() => {
          this.hideAlert();
        }, 2000);
      }
    },
    onAlertClick() {
      this.hideAlert();
    },
    hideAlert() {
      this.showAlert = false;
      this.alertType = null;
      this.alertMessage = "";
    },
    onAgenda() {
      this.$router.push("/agenda");
    },
    onSelect({ name, iso2, dialCode }) {
      console.log(name, iso2, dialCode);

      this.country = iso2;
      this.code = dialCode;
    },
  },
};
