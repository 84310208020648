/* eslint-disable */
import Setting from "../../setting.js";
import moment from "moment-timezone";

export default {
  name: "AgendaView",
  components: {},
  data: () => ({
    days: null,
    daysFuture: null,
    daysPast: null,
    schedules: null,
    user: null,
    loading: false,
    dialog: false,

    date: null,
    menu: false,
  }),
  async mounted() {
    this.loading = true;

    this.user = this.$store.getters.getUserAuth;

    /*
    console.warn({
      user: this.user,
    });
    */

    if (this.user != null) {
      await this.getData();
    } else {
      this.$router.push("/");
    }

    this.loading = false;
  },
  methods: {
    async onReload() {
      await this.getData();
    },
    async getData() {
      const result = await this.getMeetings();

      this.days = result.data.data;
      this.schedules = result.data.schedules;

      /*
      console.warn({
        days: this.days,
        schedules: this.schedules,
      });
      */

      let _date = new Date();
      _date = new Date(_date.getFullYear(), _date.getMonth(), _date.getDate());

      /*
      console.warn({
        now: _date,
        zone: this.getEventZoneTime().format("YYYY-MM-DD"),
        zonetime: new Date(this.getEventZoneTime().format("YYYY-MM-DD")),
      });
      */

      _date = new Date(this.getEventZoneTime().format("YYYY-MM-DD"));

      this.daysFuture = this.days.filter((x) => new Date(x.date) >= _date);
      this.daysPast = this.days.filter((x) => new Date(x.date) < _date);
    },
    onDateSimulationChange() {
      let _date = new Date(this.date);
      _date = new Date(_date.getFullYear(), _date.getMonth(), _date.getDate());

      this.daysFuture = this.days.filter((x) => new Date(x.date) >= _date);
      this.daysPast = this.days.filter((x) => new Date(x.date) < _date);

      this.$refs.menu.save(this.date);
    },
    async getMeetings() {
      return await this.axios.get(
        Setting[process.env.NODE_ENV].API + "Schedule/GetMeeting",
        {
          // withCredentials: true,
          params: {
            id: 7,
            email: this.user.email,
          },
          headers: {
            // 'Access-Control-Allow-Credentials':true,
            "Access-Control-Allow-Origin": Setting[process.env.NODE_ENV].Source,
          },
        }
      );
    },
    getSchedule(room, from, to, date, day, index, xxx) {
      if (!room) {
        /*
        console.warn({
          room,
          from,
          to,
          date,
          day,
          index,
        });
        */

        return {
          color: "",
          title: "",
        };
      }

      /*
      if (xxx) {
        console.warn({
          room,
          from,
          to,
          date,
          day,
          index,
        });
      }
      */

      let _from = new Date("July 21, 1983 " + from + ":00").getTime();
      let _to = new Date("July 21, 1983 " + to + ":00").getTime();

      const result = this.schedules.find(
        (x) =>
          x.room == room.id &&
          x.date == date &&
          new Date("July 21, 1983 " + x.time.from + ":00").getTime() <= _from &&
          _to <= new Date("July 21, 1983 " + x.time.to + ":00").getTime()
      );

      /*
      if (xxx) {
        console.warn({
          date: date,
          room: room,
          result: result,
          _from: _from,
          _to: _to,
          from: from,
          to: to,
          schedules: this.schedules,
        });
      }
      */

      if (result) {
        if (
          new Date("July 21, 1983 " + result.time.from + ":00").getTime() ==
          _from
        ) {
          result["name"] = result.title;
          result["register"] = true;
        } else {
          result["name"] = "";
          result["register"] = false;
        }

        return result;
      } else {
        return {
          color: "",
          title: "",
        };
      }
    },
    async onRegister(row) {
      this.dialog = true;

      if (row.isScheduled) {
        await this.postUnRegister(row.id);
      } else {
        await this.postRegister(row.id);
      }

      const result = await this.getMeetings();

      this.days = result.data.data;
      this.schedules = result.data.schedules;

      this.dialog = false;
    },
    async onCheckin(row) {
      this.dialog = true;

      if (row.isChecked) {
        await this.postUnCheckin(row.id);
      } else {
        await this.postCheckin(row.id);
      }

      const result = await this.getMeetings();

      this.days = result.data.data;
      this.schedules = result.data.schedules;

      this.dialog = false;
    },

    async postRegister(id) {
      return await this.axios.post(
        Setting[process.env.NODE_ENV].API + "Schedule/RegisterMeeting",
        {
          MeetingId: id,
          User: this.user,
        },
        {
          // withCredentials: true,
          headers: {
            // 'Access-Control-Allow-Credentials':true,
            "Access-Control-Allow-Origin": Setting[process.env.NODE_ENV].Source,
          },
        }
      );
    },
    async postSocialRegister(date, from, to) {
      return await this.axios.post(
        Setting[process.env.NODE_ENV].API + "Schedule/RegisterSocial",
        {
          Date: date,
          User: this.user,
          TimeFrom: from,
          TimeTo: to,
        },
        {
          // withCredentials: true,
          headers: {
            // 'Access-Control-Allow-Credentials':true,
            "Access-Control-Allow-Origin": Setting[process.env.NODE_ENV].Source,
          },
        }
      );
    },
    async postUnRegister(id) {
      return await this.axios.post(
        Setting[process.env.NODE_ENV].API + "Schedule/UnRegisterMeeting",
        {
          MeetingId: id,
          User: this.user,
        },
        {
          // withCredentials: true,
          headers: {
            // 'Access-Control-Allow-Credentials':true,
            "Access-Control-Allow-Origin": Setting[process.env.NODE_ENV].Source,
          },
        }
      );
    },
    async postSocialUnRegister(date, from, to) {
      return await this.axios.post(
        Setting[process.env.NODE_ENV].API + "Schedule/UnRegisterSocial",
        {
          Date: date,
          User: this.user,
          TimeFrom: from,
          TimeTo: to,
        },
        {
          // withCredentials: true,
          headers: {
            // 'Access-Control-Allow-Credentials':true,
            "Access-Control-Allow-Origin": Setting[process.env.NODE_ENV].Source,
          },
        }
      );
    },
    async postCheckin(id) {
      return await this.axios.post(
        Setting[process.env.NODE_ENV].API + "Schedule/CheckinMeeting",
        {
          MeetingId: id,
          User: this.user,
        },
        {
          // withCredentials: true,
          headers: {
            // 'Access-Control-Allow-Credentials':true,
            "Access-Control-Allow-Origin": Setting[process.env.NODE_ENV].Source,
          },
        }
      );
    },
    async postUnCheckin(id) {
      return await this.axios.post(
        Setting[process.env.NODE_ENV].API + "Schedule/UnCheckinMeeting",
        {
          MeetingId: id,
          User: this.user,
        },
        {
          // withCredentials: true,
          headers: {
            // 'Access-Control-Allow-Credentials':true,
            "Access-Control-Allow-Origin": Setting[process.env.NODE_ENV].Source,
          },
        }
      );
    },
    onAccount() {
      this.$router.push("/account");
    },
    isDateToday(eventDate) {
      // let today = new Date();
      // let today = moment().format("YYYY-MM-DD");
      let today = this.getEventZoneTime().format("YYYY-MM-DD");

      if (this.date != null) {
        today = this.date;
      }

      /*
      console.warn({
        today: today,
        event: eventDate.substring(0, 10)
      });
      */

      if (today == eventDate.substring(0, 10)) {
        return true;
      } else {
        return false;
      }
    },
    isSocialEventRegistered(row, date) {
      /*
      console.warn({
        days : this.days,
        date: date,
        registered:  this.days.find(x => x.date == date).times.find(x => x.sequence == "SOCIAL_EVENT").registrations.find(x => x.email == this.user.email)
      });
      */

      // let day = this.days.day == ""

      let registered = this.days
        .find((x) => x.date == date)
        .times.find(
          (x) =>
            x.sequence == "SOCIAL_EVENT" && x.from == row.from && x.to == row.to
        )
        .registrations.find((x) => x.email == this.user.email);

      // let registered = row.registrations.find(x => x.email == this.user.email);

      return registered ? true : false;
    },
    async onRegisterSocialEvent(date, from, to) {
      this.dialog = true;

      await this.postSocialRegister(date, from, to);

      const result = await this.getMeetings();

      this.days = result.data.data;
      this.schedules = result.data.schedules;

      // console.warn(this.days);

      // this.$forceUpdate();

      this.dialog = false;
    },
    async onUnRegisterSocialEvent(date, from, to) {
      this.dialog = true;

      await this.postSocialUnRegister(date, from, to);

      const result = await this.getMeetings();

      this.days = result.data.data;
      this.schedules = result.data.schedules;

      // console.warn(this.days);

      // this.$forceUpdate();

      this.dialog = false;
    },
    getEventZone() {
      return (
        "Eastern Daylight Time " +
        moment
          .tz(moment(), "America/Toronto")
          .format("HH:mm:ss - dddd DD. MM. YYYY")
      );
    },
    getEventZoneTime() {
      return moment.tz(moment(), "America/Toronto");
    },
  },
};
